@import 	"https://cdnjs.cloudflare.com/ajax/libs/normalize/8.0.1/normalize.min.css";
@import 	"https://use.typekit.net/yiu1net.css";
@import 	"https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap";
@import		"https://fonts.googleapis.com/css2?family=DM+Mono&display=swap";
@import		"https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/5.0.2/css/bootstrap.min.css";

// Colours

$charcoal:		#414141;

$accent:		#CB5435;

$light:			#FFF2E6;
$white:			#FFFCF9;

html, body {
	color: $charcoal;
	background-color: $light;
	margin: 0; padding: 0;
	min-width: 100%; width: 100%;
}
img {
	max-width: 100%;
}

// Typography

$heading:		"DM Mono", serif;
$script:		"IvyJournal", serif;
$body:			"DM Sans", sans-serif;

html, body {
	font-size: 16px;
	font-family: $body;
	font-weight: 400;
}
h1, h2, h3, h4, h5 {
	font-family: $body;
	font-style: normal;
	font-weight: 500;
}
p, span, a, ul, li {
	font-family: $body;
	font-style: normal;
	font-weight: 400;
}
h1 {
	font-size: 4.375em;
	color: $light;
}
h2 {
	font-size: 2.5em;
}
h3 {
	font-family: $heading;
	text-transform: uppercase;
}
h3, #intro p {
	font-size: 1.5em;
	letter-spacing: 0.05em;
}
h4, p.large {
	font-family: $script;
	font-size: 1.875em;
}
h4 {
	color: $accent;
}
p {
	font-size: 1.25em;
	line-height: 1.5em;
	color: $charcoal;
}
p:last-child {
	margin-bottom: 0;
}
p.small {
	font-size: 1.0em;
}
p.bold {
	font-weight: 700;
}
.rsvp {
	display: inline-block;
	padding: 0 2.0em;
	font-size: 1.5em;
	line-height: 1.875em;
	background-color: $accent;
	color: $white;
	text-decoration: none;
	font-weight: 700;
}
.rsvp:hover {
	background-color: darken($accent, 5%);
	color: lighten($white, 5%);
}
#hero, #intro {
	text-align: center;
}
#hero p {
	color: $light;
}
#intro {
	h2 {
		margin-bottom: 1.0em;
	}
	a {
		margin-top: 1.0em;
	}
}
#details h3 {
	padding-bottom: 1.0em;
}
.details-section {
	p {
		margin-bottom: 2.0em;
	}
}
#schedule h3 {
	margin: 0 0 1.5em;
}
.schedule-section {
	h4 {
		margin: 0;
		padding-top: 0.5em;
		letter-spacing: 0.2em;
	}
	p {
		padding: 1em 0 1.0em;
	}
}
.schedule-section:last-child {
	@media (max-width: 767px) {
		padding-bottom: 4.0em;
	}
}
.info-section {
	p.large {
		margin-bottom: 1.5em;
		color: $accent;
	}
}
.info-icon {
	@media (min-width: 768px) {
		text-align: center;
	}
}
#faq {
	h3 {
		margin-bottom: 1.0em;
	 }
	h3, h4 {
		color: $light;
	}
	h4 {
		font-size: 1.5em;
		margin-bottom: 1.0em;
	}
	p {
		color: darken($light, 10%);
	}
}
#registry h3 {
	margin-bottom: 1.5em;
}
#rsvp p.large {
	margin-bottom: 2.0em;
}

// Layout

#hero, #intro, #schedule, #registry, #info, #faq, #rsvp {
	padding-top: 8.125em;
	padding-bottom: 8.125em;
}
#hero {
	@media (min-width:1200px) {
		min-height: 60em;
	}
	padding-bottom: 24.0em;
	background-image: url('../img/img-hero.png');
	background-position: center;
	background-size: cover;
	color: $white;
	h1 {
		letter-spacing: 0.1em;
	}
}
#details-photo {
	background-image: url('../img/img-info.png');
	background-size: cover;
	background-position: left;
	position: relative;
}
#details-photo-overlay {
	width: 4em;
	height: 100%;
	display: block;
	background-color: $accent;
	flex: 1;
	z-index: 100;
	position: absolute;
	top: 0;
	left: 0;
}
#details-text {
	padding-top: 8.125em;
	padding-bottom: 8.125em;
}
.details-brick, .schedule-section, .info-section {
	border-bottom: 1px solid $charcoal;
}
.details-brick {
	padding-top: 2.0em;
}
.details-brick-last, .schedule-section:last-child, .info-section:last-child {
	border: 0;
}
.schedule-section:last-child {
	margin-bottom: 1.0em;
}
#details, #registry {
	background-color: $white;
}
#schedule {
	p.small {
		padding-top: 1.0em;
		margin-bottom: 0;
	}
}
@media (max-width: 767px) {
	#schedule h3 {
		text-align: center;
	}
	.schedule-section {
		width: 50%;
		float: left;
		display: block;
		border: 0;
		margin: 0;
		text-align: center;
	}
	.schedule-section:last-child{
		width: 100%;
		margin-bottom: 0;
	}
}
.info-section {
	padding: 4.0em 0 2.0em;

}
@media (max-width: 768px) {
	.info-icon {
		text-align: center;
	}
	.info-section {
		padding-bottom: 4.0em;
	}
	.info-section:last-child {
		padding-bottom: 0;
	}	
}
#faq {
	background-color: $accent;
	.faq-section {
		padding: 2.0em 0;
	}
}
#rsvp {
	text-align: center;
	h2 { 
		font-size: 4.375em;
		letter-spacing: 0.1em;
	}
	p {
		font-family: $script;
	}
}